:root {
	--evaluation_favorite_star: #e8d306;
	--wait_dots: #6287AE;

	--notify_text: rgb(18, 18, 18, 0.7);
	--notify_background_info: rgb(131, 133, 135, 0.3);
	--notify_border_info: rgb(131, 133, 135, 0.5);
	--notify_background_error: rgb(255, 107, 107, 0.3);
	--notify_border_error: rgb(255, 107, 107, 0.5);
	--notify_background_success: rgb(175, 212, 169, 0.3);
	--notify_border_success: rgb(175, 212, 169, 0.5);

	--sex_male: rgba(30, 2, 203, 0.9);
	--sex_female: rgba(198, 6, 144, 0.9);

	--message_block: #FAE7FD;
	--standart_color: #892E98;
		
}



body[scheme="client_light"],body[scheme="bright_light"] .Switch__self:checked+.Switch__pseudo {
	background: #dbc8de !important;
}

/* светлые темы  */
body[scheme="client_light"], body[scheme="bright_light"]  {
	color: #fff;
	--notify_text: rgb(18, 18, 18, 0.7);
	--message_block: #FAE7FD;
	--standart_color: #892E98;

	--header_tint_alternate: #892E98;
	--button_secondary_foreground: #892E98;
	--button_outline_border: #892E98;
	--button_outline_foreground: #892E98;
	--button_primary_background: #892E98;
	--button_secondary_background:rgba(250, 231, 253, 0.9);
	--header_background: #892E98;
	--header_text: #fff;
	--tabbar_active_icon: #892E98;
	--tabbar_background: rgba(250, 231, 253, 0.92);
	--header_alternate_background: rgba(250, 231, 253, 1);
	--background_content: #FDF1FF;
	--header_tint: #fff;
	--accent: #892E98;
}


/* темные темы */
body[scheme="client_dark"], body[scheme="space_gray"] {
	color: #fff;
	--notify_text: #fff;
	--message_block: rgb(131, 133, 135, 0.5);
	--standart_color: rgb(131, 133, 135, 0.5);
}

body::-webkit-scrollbar-track {
	background-color: var(--separator_common);
}

body::-webkit-scrollbar {
	width: 7px;
	background-color: #fff;
}

body::-webkit-scrollbar-thumb {
	background-color: var(--attach_picker_tab_inactive_text);
	border-radius: 6px;
}

.flex_start {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.flex_end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.flex_center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex_arrow {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.p-10 {
	padding: 10px;
}

.pb-0 {
	padding-bottom: 0px;
}
.pt-0 {
	padding-top: 0px;
}
.pt-10 {
	padding-top: 10px;
}
.pt-20 {
	padding-top: 20px;
}
.pt-30 {
	padding-top: 30px;
}
.pt-15 {
	padding-top: 15px;
}
.pt-50 {
	padding-top: 50px;
}
.pt-70 {
	padding-top: 70px;
}
.pt-5 {
	padding-top: 5px;
}
.pb-5 {
	padding-bottom: 5px;
}
.pb-15 {
	padding-bottom: 15px;
}
.pr-5 {
	padding-right: 5px;
}
.pr-10 {
	padding-right: 10px;
}
.pl-5 {
	padding-left: 5px;
}
.pl-10 {
	padding-left: 10px;
}
.mr-5 {
	margin-right: 5px;
}
.ml-5 {
	margin-left: 5px;
}

.fontTitle {
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 43px;
	text-align: center;
	letter-spacing: -0.154px;
	color: var(--standart_color);
}

.fontUserName {
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	letter-spacing: -0.154px;
}

.heartbeatBox {
	height: 130px;
}

.heartbeatIcon {
	color: var(--standart_color);
}

.heartbeat_start {
	animation: heartbeat 1s infinite;
}
@keyframes heartbeat
{
  0%
  {
    transform: scale( 1 )
  }
  20%
  {
    transform: scale( 1.25 )
  }
   40%
  {
    transform: scale( 1.5 )
  }
}

.faVenus {
	color: var(--sex_female);
}
.faMars {
	color: var(--sex_male);
}

.__ChatMessageBlock {
	
	background: var(--background_content);
}

.__ChatControlBlock {
	background: var(--background_content);
	padding-bottom: 6px;
	padding-top: 6px;
}

.__ChatCustomInput {
	width: 100%;
}

.__ChatCustomInput .FormField__border {
	border-radius: 0px;
	border: 0px;
	background: var(--background_content);
}
.__ChatCustomInput .FormField__border:focus {
	background: var(--background_content);
}

.__ChatCustomInput input {
	width: 100%;
	background: var(--background_content);
}

.__ChatCustomInput input:focus {
	width: 100%;
	background: var(--background_content);
}

.__ChatBlock {
	padding-bottom: 95px;
}

.__ChatSystemMessage {
	color: var(--content_placeholder_text);
	font-weight: 700;
	font-size: 12px;
}

.__ChatMessage {
  display: inline-flex;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: var(--message_block);
}

.__ChatHiddenInput {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.__ChatProgressBlock {
	background-color: var(--background_content);
}

.__TwisterAnswerBlock {
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.__Notify_title {
	font-size: 17px;
	line-height: 20px;
	letter-spacing: -0.408px;
}


.photoBox img {
	position: relative;
	border-radius: 200px;
	z-index: 9999;
}

.thisVip_1 img {
	border: 5px solid #E0A50B;
}

.__Twister_avatar {
	position: relative;
}

.thisVip_1 .__Twister_avatar:after {
	top: -5px;
	left: -5px;
	height: 52px;
	width: 52px;
	content: "";
	position: absolute;
	background-color: transparent;
	background: url(./svg/crown-solid.png) 100% 100% no-repeat;
	background-size: cover;
	border: 0px;
	border-radius: 2px;
	background-repeat: no-repeat;

	-moz-transform: rotate(-45deg); 
	-ms-transform: rotate(-45deg); 
	-webkit-transform: rotate(-45deg); 
	-o-transform: rotate(-45deg); 
	transform: rotate(-45deg);
}

.thisHidden_1 {
	filter: blur(13px);
}

.thisHidden img {
	filter: blur(5px);
}

.thisVip img {
	z-index: 2;
	border: 3px solid #E0A50B;
}
 
.thisVip .Avatar__in:after {
	z-index: 1;
	top: -5px;
	left: -5px;
	height: 20px;
	width: 20px;
	content: "";
	position: absolute;
	background-color: transparent;
	background: url(./svg/crown-solid.png) 100% 100% no-repeat;
	background-size: cover;
	border: 0px;
	border-radius: 2px;
	background-repeat: no-repeat;

	-moz-transform: rotate(-45deg); 
	-ms-transform: rotate(-45deg); 
	-webkit-transform: rotate(-45deg); 
	-o-transform: rotate(-45deg); 
	transform: rotate(-45deg);
}

/*
	фикс не влезубщей короны
*/
.Cell__before-in {
	padding-top: 6px;
	padding-bottom: 6px;
}

._Text_link:hover {
	cursor: pointer;
}

.__ModalFontTitle {
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 21px;
	letter-spacing: -0.154px;

}

.__ModalFontDescription {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: var(--content_placeholder_text);
}

.__Settings_faCrown {
	color: var(--standart_color);
	padding: 10px 16px 10px 0;
	width: 24px;
	height: 24px;
}
.svg-inline--fa.fa-w-20 {
	width: 24px;
	height: 24px;
}
.__BuyVip_title {
	font-weight: 700;
	letter-spacing: 0.408px;
	font-size: 18px;
	line-height: 21px;
	color: var(--standart_color);
}